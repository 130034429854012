<template>
  <div>
    <div class="white-bg-container">
      <form @submit.prevent="addTeamMember" class="mb-3">

        <div v-if="error" class="alert alert-dismissible alert-warning">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <h4 class="alert-heading">Error!</h4>
            <p class="mb-0">{{error}}</p>
        </div>

        <!-- <div v-if="success" class="alert alert-dismissible alert-success">
          <button type="button" class="close" data-dismiss="alert">&times;</button>
          <h4 class="alert-heading">Success!</h4>
          <p class="mb-0">{{success}}</p>
        </div> -->

        <!-- Headers -->
        <div class="row formheadings">
          <div class="col-sm-8">Add Team Members</div>
          <div class="col-sm-4">Notification</div>
        </div>

        <!-- Form Elements -->
        <div class="row">
          <div class="col-sm-8">
            <div class="row">
                <div class="col-sm-6">
                  <md-field md-clearable>
                    <label>First name</label>
                    <md-input v-model="user.FirstName" required></md-input>
                  </md-field>
                </div>
                <div class="col-sm-6">
                  <md-field md-clearable>
                    <label>Last name</label>
                    <md-input v-model="user.LastName" required></md-input>
                  </md-field>
                </div>
                <div class="col-sm-6">
                  <md-field md-clearable>
                    <label>Phone number</label>
                    <md-input v-model="user.PhoneNumber" @keypress="isPhoneNumber($event)" required></md-input>
                  </md-field>
                </div>
              </div>
              <div class="row">
                  <div class="col-12" style="text-align: right; padding-top: 15px;">
                    <v-btn plain @click.prevent="resetUserForm()"> Cancel </v-btn>
                    <v-btn type="submit" depressed color="primary" small>{{this.userid ? 'UPDATE USER' : 'ADD NEW USER'}}</v-btn>
                  </div>
                  <div class="col-sm-12" v-if="success == 'Added'">
                      <div class="alert alert-dismissible alert-success">
                        <button type="button" class="close" data-dismiss="alert" @click="success=''">&times;</button>
                        <span class="alert-headeing">Confirmation</span><br>
                        Your contact was added and a text message notified them to download the mobile app.
                      </div>
                  </div>
                </div> 

          </div>
          <div class="col-sm-4">
            <div class="col-sm-12">
              Team members receive a text message when you add them, with a link to go download the mobile app. Available on iOS & Android. 
            </div>
            <div class="col-sm-12" style="text-align:center; padding-top:30px;">
              <img src="./assets/sig-2-i-phone-app-logo-white-1.png">
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="transparent-bg-container" v-if="success == 'Deleted'">
      <div class="row" style="margin-top:15px; margin-bottom:5px;">
        <div class="col-sm-12">
          <div class="alert alert-dismissible alert-success" style="background:#F5E0AB">
            <button type="button" class="close" data-dismiss="alert" @click="success=''" style="color:#828282">&times;</button>
            <span class="alert-headeing">Confirmation</span><br>
            A contact has been deleted. They cannot receive any more geofenced messages from you.
            

          </div>
        </div>
      </div>
    </div> 
<div class="transparent-bg-container">
    <table class="table table-striped">
        <thead>
          <tr>
            <th>
              <!-- Serial Number -->
            </th>

            <th>
              Name
            </th>

            <th>
              Phone Number
            </th>
            
            <th>

            </th>

          </tr>
        </thead>

        <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ 1 }}</td>
              <td>{{ user.FirstName + ' ' + user.LastName }}</td>
              <td>{{ user.PhoneNumber }}</td>
              <td>
                <span title="Edit">
                  <span class="action-button" @click="editTeamMember(user)">
                      <v-icon size="20px"  title="Edit">mdi-square-edit-outline</v-icon>  
                  </span>
                </span> 

                <span style="cursor:pointer" @click="deleteTeamMember(user.UserID)">
                  <v-icon size="20px" color="red" title="Delete">mdi-trash-can-outline</v-icon>
                </span>

              </td>
            </tr>          
      </tbody>
    
      </table>

    </div>
    <UpdateProfile 
        v-if="popupTriggers.buttonTrigger" :Receiver="editUser" 
        :TogglePopup="() => TogglePopup('buttonTrigger')" :ProfileSaved="(data) =>userUpdated(data)" >
    </UpdateProfile>
  </div>
</template>

<script>

const API_URL = process.env.VUE_APP_APIDOMAIN+"/teams";
console.log(API_URL);
import { VBtn, VIcon } from "vuetify"
import UpdateProfile from './components/UpdateProfile'; 
import { ref } from 'vue';

export default {
  name: "teams",
  setup () {
            const popupTriggers = ref({
                buttonTrigger: false
            });		
            const TogglePopup = (trigger, user) => {
                popupTriggers.value[trigger] = !popupTriggers.value[trigger]
            }
            return {
                UpdateProfile,
                popupTriggers,
                TogglePopup,
            }
  },
  components:{VBtn, VIcon, UpdateProfile},
  data: () => ({
    pager: {},
    error: "",
    search:"",
    sort:"",
    order:"",
    userid:0,
    currentSort: 'name',
    currentSortOrder: 'asc',
    users: [],
    user: {},
    editUser:{},
    success:"" ,
    serverError: "",
    token: localStorage.getItem("tknds") || '',   
  }),
  computed: {
  },
  mounted() {   
    window.pageTitle = "Teams";
    this.user.Locale = "English";
    console.log('User detail = ', this.$root)
    this.user.ClientID = this.$root.userdetail.user[0].ClientID;
    this.userid = this.$route.query.userid ? this.$route.query.userid : 0;
    if(this.userid){
      this.getQueryData(this.userid);
    }
  },
  methods: {
    searchTeams(){
     /* if (this.search.trim() != ""){
        let query = Object.assign({}, this.$route.query);
        delete query.page;
        this.$router.replace({ query });

        this.$router.push({ query: Object.assign({}, this.$route.query, { search: this.search }) });        
      } else {
        let query = Object.assign({}, this.$route.query);
        delete query.search;
        this.$router.replace({ query });
      }  */    
    },
    isPhoneNumber(evt) {
        const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '-'];
        const keyPressed = evt.key;
        
        if (!keysAllowed.includes(keyPressed)) {
              evt.preventDefault()
        }
    },
    truncateText(text, length){     
        if (!text){return text}
        return 'Char count ' +String(text).length;
        //if (String(text).length < length){ return text }
        //return String(text).substring(0, length) + '...';
    },
    orderBy(col){
      if (this.sort == col && this.order == "ASC"){
        this.order = "DESC"
      } else {
        this.order = "ASC"
      }
      this.sort = col;

      let query = Object.assign({}, this.$route.query);
      delete query.sort;
      delete query.order;
      this.$router.replace({ query });
      this.$router.push({ query: Object.assign({}, this.$route.query, { order: this.order, sort:this.sort }) });

    },
    userUpdated(data){
      this.users.map((u)=>{ 
        if (u.UserID == data.UserID){
          u.FirstName = data.FirstName;
          u.LastName = data.LastName;
          u.PhoneNumber = data.PhoneNumber;
        } 
        return u; 
      })
      this.popupTriggers.buttonTrigger = false;
    },
    addTeamMember(event, todelete = false){

      //this.teams.actor = this.$root.loggedInUser;
      this.user.ClientID = this.$root.userdetail.user[0].ClientID;
      let data = todelete ? todelete : this.user;
      let action = todelete ? "Deleted" : "Added";
      action = this.userid ? "Updated" : action;
      
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          "Authorization": "Bearer "+this.token
        }
      }).then(response => response.json())
        .then(result => {

            if(result.AuthSuccess == 0){
                this.serverError = result.message
                this.$root.logout();
                // alert(this.serverError);
                // setTimeout(() => {
                //     //console.log("NoData");
                //     this.$router.push('/logout')
                // },2000);							           
            }
            
            if (result.details) {
              // there was an error...
              const error = result.details
                .map(detail => detail.message)
                .join(". ");
              this.error = error;
              setTimeout(()=>this.success = "", 5000);
            } else {
              this.error = "";
              this.success = action;  
              this.resetUserForm();
              //Show refreshed data;
              this.getQueryData();
              setTimeout(()=>this.success = "", 5000);              
            }
          });
    },
    editTeamMember(user){
      this.editUser = user;
      this.TogglePopup('buttonTrigger');
    },
    resetUserForm(){
      this.userid = 0;
      this.user = {};
    },
    deleteTeamMember(id){
      /* if(!confirm("Do you really want to delete the team member?")){ return;}
      this.addTeamMember(null, {deleteRow: id}) */
    },
    modifiedDate(raw){
      if(raw == ""){
        return raw;
      }
      var date = new Date(raw);
      return date.getFullYear() +'/'+(date.getMonth()+1)+'/'+date.getDate();    
    },
    getQueryData(userid=0){
        var search = this.$route.query.search || "";
        var page = parseInt(this.$route.query.page) || 1;
        console.log('Client ID = ', this.$root.userdetail.user[0])
        var url = API_URL+'?page='+ page;
        url+= "&ClientID="+this.$root.userdetail.user[0].ClientID;
        url+= "&Role="+ this.$root.userdetail.user[0].Role;
        if (search != ""){
          this.search = search;
          url += '&search='+search;
        }
        if (this.sort){
          url += '&sort='+this.sort;
          url += '&order='+this.order;
        }
        if(userid){
          url += '&id='+userid;
        }
        fetch(url, { 
              method: 'GET',
              headers: {
                  "content-type": "application/json",
                  "Authorization": "Bearer "+this.token
              } 
          })                      
            .then(response => response.json())
            .then((result) => {
                if(result.AuthSuccess == 0){
                    this.serverError = result.message
                    this.$root.logout();
                    // alert(this.serverError);
                    // setTimeout(() => {
                    //     //console.log("NoData");
                    //     this.$router.push('/logout')
                    // },2000);							           
                }
                if(userid){
                  this.user = result.team;
                  console.log(this.user);
                } else {
                  this.users = result.teams.docs;
                  this.pager = result.teams.pager;
                }                            
        });
    },
    changePage(page){
      this.$router.push({ query: Object.assign({}, this.$route.query, { page: page }) });
    },
    customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort

          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }

          return b[sortBy].localeCompare(a[sortBy])
        })
      }
  },
  watch: {
      '$route.query': {          
          immediate: true,
          handler() {         
              this.getQueryData()             
          }
      }
  }
};
</script>

<style scoped>
  .sortable{
    color:blue;
    font-weight:bold;
    cursor:pointer;
  }

  .transparent-bg-container {
    margin: 0px 60px;
    /* padding: 33px 33px 32px 20px; */
    border-radius: 20px;
  }

  .new-message-btn{
    padding: 10px 24px 10px 16px;
    background: #344d7a;
    color: white !important;
    border-radius: 50px;
    border-color: #344d7a;
  }
  .new-message-btn:hover{
    text-decoration:none !important;
    color:white !important;
    background:#335491 !important;
  }
  .new-message-btn .md-icon{
    color: white !important;
  }


  .md-clearable{
    background:#ffffff;
  }
  .md-clearable label, .md-clearable input{
    padding-left:10px !important;
  }

  .md-table.md-theme-default .md-table-alternate-header, .md-table.md-theme-default .md-table-content{
    background-color:transparent !important;
  }
  .md-card.md-theme-default, .md-card.md-theme-default .md-card-expand .md-card-actions{
    background-color:transparent !important;
  }
  .md-table{
    box-shadow:none !important;
  }
  .action-button{
    cursor:pointer;
  }

  .action-button svg, .action-button-delete svg{
    fill: gray;
  }

  .action-button svg:hover{
    fill:rgb(64, 102, 207);
  }

  .action-button-delete svg:hover{
    fill: red;
  }
  .btn-link{
    color:#858287;
  }
  .btn-link:hover, .btn-link:visited, .btn-link:focus{
    text-decoration:none;
  }
  .alert{
    width:100%;
    font-family: Roboto;
    position: relative;
    padding:10px 10px;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 10px;
  }
  


  .alert-success {
    color: #333;
    background-color: #D6FCC4;
    border: none !important;
    font-size: 16px;
  }
  .alert-headeing{
    font-size:20px;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.3rem .6rem;
    color: #80B47B;
    font-weight:300;
    opacity: .9;
  }
  
</style>
