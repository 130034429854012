<template>
    
    <v-btn class="form-control dropdown-btn" style="width:auto; min-width:130px;" id=menu-activator @click="showDatePicker=true" :ripple="false">
        {{formatedDate}}
    </v-btn>
    <div>
        <v-menu activator="parent" v-model="showDatePicker" :close-on-content-click="false">
        <div>
            <v-date-picker v-model="selectedDate" :min="min" @update:modelValue="datePickerDateSlected"  class="custom-date-picker"></v-date-picker>
        </div>
    </v-menu>
    </div>
  </template>
  
  <script setup>
    import { ref, watch, defineEmits, defineProps } from 'vue';
    const emit = defineEmits(['date-selected', 'custom-date-selected']);
    const props = defineProps(['selectedDate', 'min']);
    const formatDate = function(dateString) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        let formattedDate = '';
        if (dateString){
            formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        } else {
            formattedDate = new Date().toLocaleDateString('en-US', options);
        }
        return formattedDate;
    }
    let showDatePicker = ref(false);
    const selectedDate = ref(new Date(props.selectedDate));
    let min = ref(props.min);
    let datePickerDateSlected = function(d){
        //IF date is selected by date picker manually 
        if(formatDate(formatedDate.value.toString()) != formatDate(d)){
            emit('custom-date-selected')
        } 
        
    }
    
    let formatedDate = ref(formatDate(selectedDate.value));
        
    watch(
        () => selectedDate.value,
        newValue => {
            showDatePicker.value = false;
            formatedDate.value = formatDate(newValue);
            emit('date-selected', formatedDate.value);
            }
        )
        watch(
            () => props.min, newValue => {
                min.value = newValue;
            }
        )
        watch(
            () => props.selectedDate, newValue => {
                selectedDate.value = new Date(newValue);
            }
        )
    
 
  </script>
<style lang="scss">
    .day-selector{
        width:34px;
        height:34px;
        float:left;
        border: 1px solid #BDBDBD;
        text-align:center;
        line-height:34px;
        margin-left:10px;
        border-radius:5px;
        color:#828282;
        cursor:pointer;
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }
    .selected{
        background:#839BB8;
        color: white;
    }
</style>