import { createApp } from 'vue'
import App from './App.vue'
import { h } from 'vue'
import { ref } from 'vue';
//import VueRouter from 'vue-router';
import { createRouter, createWebHistory, useRouter, useRoute } from 'vue-router';

import vuetify from './plugins/vuetify';
import VueGoogleMaps from "@fawmi/vue-google-maps" // Import package
//import {VueMaterial} from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
//import 'vue-material/dist/theme/default.css'

import { loadFonts } from './plugins/webfontloader'
import firebase from 'firebase/compat/app';
import 'firebaseui/dist/firebaseui.css'


import 'firebase/compat/auth';
import { config } from './helpers/firebaseConfig'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle';
//import '@mdi/font/css/materialdesignicons.css'

import AuthView from './views/AuthView.vue';
import HomeView from './views/HomeView.vue';
import Auth from './components/Auth.vue';
import AuthNotAllowed from './components/AuthNotAllowed.vue';
import AuthLogout from './components/AuthLogout.vue'
import ComposeMessage from './views/ComposeMessage/ComposeMessage.vue';
import DownloadApp from './views/DownloadApp/DownloadApp.vue'
import Teams from './Teams.vue';
import ContactsView from './views/contacts/ContactsView.vue';
import MessageView from './MessageView.vue';
import TermsPrivacyView from './views/TermsPrivacyView.vue';

import 'vuetify/dist/vuetify.min.css';
import eventBus from './eventBus';
import './assets/scss/main.scss';
import Group from './views/Group/Group.vue';
import './assets/messages.css';

console.log('Environment API URL:', process.env.VUE_APP_APIDOMAIN || 'Environment variable not set');

const routes = [
	{ path: "/", component: HomeView, name: "/", meta: { title: "Sig2 Operator Console" } },
	{ path: "/messages", component: MessageView, name: "ourcontents", meta: { title: "Messages" } },
	{ path: "/messages/editor", component: ComposeMessage, name: "ComposeMessage", meta: { title: "Message Editor" } },
	{ path: '/login', component: AuthView, name: 'login', meta: { title: "Login" } },
	{ path: '/logout', component: AuthLogout, name: 'logout', meta: { title: "Logout" } },
	{ path: '/authnotallowed', component: AuthNotAllowed, name: 'authnotallowed', meta: { title: "Auth Not allowed" } },
/* 	{ path: '/teams', component: Teams, name: 'teams', meta: { title: "Teams" } }, */
	{ path: "/contacts", component: ContactsView, name: "contacts", meta: { title: "Contacts" } },
	{ path: "/download-app", component: DownloadApp, name: "DownloadApp", meta: { title: "DownloadApp" } },
	{ path: "/group", component: Group, name: "Group", meta: { title: "Groups" } },
	{ path: "/terms", component: TermsPrivacyView, name: "Terms", meta: { title: "Terms" } },
	{ path: "/privacy", component: TermsPrivacyView, name: "Policy", meta: { title: "Policy" } },
	{
		path: '/:pathMatch(.*)*',
		redirect: '/',
	  },
]

const router = createRouter(
	{ routes, history: createWebHistory(), },
);

createApp({
    App,
	el: '#app',
	data: ()=>({
		loggedInUser: "",
		unauthorizeuser: false,
		loggedout:false
	}),
	methods: {
		logout(showLogoutPage = true) {
			let app = this;
			firebase.auth().signOut().then(function () {
				// Sign-out successful.
				// Clear local storage and session storage
				localStorage.clear();
				sessionStorage.clear();
		
				// Clear Vuex state
				//store.commit('clearUserData');

				app.loggedInUser = "";
				app.userdetail = {};
				app.uiooo = {};
				app.unauthorizeuser = false;
				app.loggedout = showLogoutPage;
				//app.$router.push('/auth')
			}, function (error) {
				alert("Errot in logging out, please try again")
				console.log(+ error)
				// An error happened.
			});
		},
		getAuthData() {
			return firebase;
		},
		setOperatorRadiusUnit(){
			let instance = this;
			fetch('https://ipinfo.io/json?token=0d62e40c4be101')
				.then(function(response) {
					return response.json(); // Parse the response body as JSON
				})
				.then(function(data) {
					localStorage.setItem("operator_radius_unit", data.country == "US" ? 1 : 2)
					localStorage.setItem("operator_radius", data.country == "US" ? 1609.344 : 1000)
				})
				.catch(function(error) {
					console.error('Error:', error);
				});
		},
	},
    setup(){
    },
    created(){
        console.log('fresh deploy!')
		//alert('start')
		this.setOperatorRadiusUnit();
		var thisapp = this;
		firebase.initializeApp(config);
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				if (localStorage.getItem('justLoggedIn') == null) {
					localStorage.setItem('justLoggedIn', 'true');
				}
				fetch(process.env.VUE_APP_APIDOMAIN+"/isuserallowed?email=" + encodeURIComponent(user.email), {
					method: "GET",
					headers: {
						"content-type": "application/json"
					}
				}).then(response => response.json())
					.then(result => {	
										
						//Need to add another condition if users UserActive flag=1
						//console.log('Existing user :: ', result);
						if (result.user.length > 0 && result.user[0].UserActive) {
							// Set the Login Auth Toke After User Login
							if(result.token){
								// Set the Auth Token
								localStorage.setItem("tknds", result.token);
							}
							thisapp.loggedInUser = 'Guest User';
							if(result.user[0].FirstName){
								thisapp.loggedInUser = result.user[0].FirstName
							}
							if(result.user[0].LastName){
								thisapp.loggedInUser += ' ' + result.user[0].LastName;
							}
							//First Login ?
							thisapp.userdetail = result;
							console.log("thisapp.userdetail = ", thisapp.userdetail)
							//thisapp.setOperatorRadiusUnit();
							thisapp.unauthorizeuser = false;
							let currentroute = ref(this.$router.currentRoute).value.path;							
							
							// if (!result.user[0].PhoneNumber && currentroute.indexOf('messages') == -1){
							// 		alert("St1-"+result.user[0].PhoneNumber)
							// 		thisapp.userdetail.firsttimeuser = true;
							// 		thisapp.$router.push('/contacts');
							// }  

							/********************************************************/
							//if (!result.user[0].PhoneNumber && localStorage.getItem('justLoggedIn') === 'true'){
							if (!result.user[0].PhoneNumber){
								thisapp.userdetail.firsttimeuser = true;
								thisapp.$router.push('/contacts');
							}  else {

								//alert('here')
								if (ref(this.$router.currentRoute).value.path == "/"){
									thisapp.$router.push('/messages');
								}
							}
							/*********************************************************/ 
							
							//thisapp.$router.push('/messages');
						} else {
							//This is a new User
							console.log('This is a new User!')
							thisapp.unauthorizeuser = true;
							let userData = {};
							userData.SSOType = (user.providerData[0].providerId == "google.com") ? 'Google': 'Email';
							userData.LastName = '';
							userData.FirstName = '';
							userData.Email = userData.UserName = user.email
							if (user.displayName.indexOf(' ') != -1){
								userData.FirstName = user.displayName.split(' ')[0];
								userData.LastName = user.displayName.split(' ')[1];
							} else {
								userData.FirstName = user.displayName;
							}
							
							if (result.user.length > 0){
								console.log(result);
								console.log('InActive User')
								thisapp.userdetail = result;
								thisapp.unauthorizeuser = true;
								thisapp.loggedInUser = userData.FirstName +' '+userData.LastName;
								thisapp.$router.push('/authnotallowed')
							} else {
								console.log('First time user');
								fetch(process.env.VUE_APP_APIDOMAIN+'/savesaasuser', {
									method: "POST",
									body: JSON.stringify(userData),
									headers: {
									  "content-type": "application/json"
									}
								  }).then(response => response.json())
									.then(result => {
										console.log('user data saved!');
										if (result.success) {
											fetch(process.env.VUE_APP_APIDOMAIN+"/isuserallowed?email=" + userData.Email, {
												method: "GET",
												headers: {
													"content-type": "application/json"
												}
											}).then(response => response.json())
											.then(result => {
												if (result.user.length > 0 && result.user[0].UserActive) {
													// Set the Login Auth Toke After User Login
													if(result.token){
														// Set the Auth Token
														localStorage.setItem("tknds", result.token);
													}
													thisapp.unauthorizeuser = false;
													thisapp.loggedInUser = 'Guest User';
													if(result.user[0].FirstName){
														thisapp.loggedInUser = result.user[0].FirstName
													}
													if(result.user[0].LastName){
														thisapp.loggedInUser += ' ' + result.user[0].LastName;
													}
													//thisapp.userdetail = result;
													
													thisapp.userdetail = result;
													thisapp.userdetail.firsttimeuser = true;
													//console.log('New  User ::', result)
													thisapp.$router.push('/contacts');
													//console.log('user signup successful');
												} else {
													thisapp.userdetail = result;
													thisapp.unauthorizeuser = true;
													thisapp.loggedInUser = userData.FirstName +' '+userData.LastName;
													thisapp.$router.push('/authnotallowed')
												}
											})

										} else {
											console.log(result)
											console.log('user signup failed');
											thisapp.unauthorizeuser = true;
											thisapp.$router.push('/authnotallowed')        
										}
									  });
							}
							
						}

					});

			} else {
				let currnt_route = ref(this.$router.currentRoute).value.path;
				if (currnt_route!='/terms' && currnt_route!='/privacy' && currnt_route!='/login'){
					this.$router.push('/');

				} else {
					this.$router.push(currnt_route);
				}
			}
		});
    },
    render() {
		if (localStorage.getItem('justLoggedIn') == null && !this.loggedout) {
			let currnt_route = ref(this.$router.currentRoute).value.path;
			if (currnt_route == "/login"){
				return h(AuthView);
			}
			return h(HomeView);
		}
		if (this.loggedInUser && !this.unauthorizeuser) {
            return h(App, {loggedInUser: this.loggedInUser  })
		} else {
			let template = false;
			if (this.loggedout){
				this.$router.push('/logout')
				template = AuthLogout;
			} else {
				//console.log('current route path ::',ref(this.$router.currentRoute).value.path);
				template = (this.unauthorizeuser && this.loggedInUser) ? AuthNotAllowed : HomeView;
			}
			return h(template,{ loggedInUser: this.loggedInUser } );
		}

	}
}).use(vuetify).use(router).use(VueGoogleMaps, {
	load: {
		key: "AIzaSyAr5X7eblwPkDDuB9Xvw8NSsOtzYuXYUOQ",
		libraries: "places"
	}
}).provide('eventBus', eventBus).mount('#app')

var titlePrefix = "Sig2 Operator Console";
document.title = titlePrefix;
router.beforeEach((to, from, next) => {
	document.title = titlePrefix;
	localStorage.setItem("previousPage", from.path);

	if (typeof to.meta != "undefined" && typeof to.meta.title != "undefined") {
		document.title = titlePrefix + ' : ' + to.meta.title;
		//console.log('route for each')
	}
	if (to.path == "/"){
		document.title = titlePrefix;
	}
	
	speechSynthesis.cancel();
	next();
	
})

  


